<template>
  <div>
  <v-app class="bg">
    <navTop />
    <v-main>
      <router-view  style="overflow: hidden;"/>
    </v-main>
    <footerBottom/><disclamerBottom/>
  </v-app>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import navTop from './NavTop.vue'
import footerBottom from './Footer.vue'
import disclamerBottom from './Disclamer.vue'

export default defineComponent({
  name: 'App',
  components: {
    navTop,footerBottom,disclamerBottom
  },
})
</script>

<style scoped>
/* Apply background image to the entire app */
.bg {
  background-image: url('../assets/img/bg.jpg');
  background-size: cover; 
  width: 100%;
  margin-top: -30px; height: 51rem;
  position: relative;
}

/* Make sure the body and html take up full height for proper scrolling */
html, body {
  height: 100%;
  margin: 0;
}

/* Ensure the v-app container fills the page and allows scrolling */
.v-application {
  min-height: 100%;
}

/* Allow content to scroll within the main container */
.v-main {
  overflow-y: auto; /* Enables scrolling when content overflows */
  position: relative;
  z-index: 1;
}
</style>

<style>
.glass-card {
  background: rgba(251, 240, 240, 0.756) !important; /* Adjust the color and opacity */
  backdrop-filter: blur(5px) !important;  
}
</style>
