import 'vuetify/styles' // Vuetify styles
import { createVuetify } from 'vuetify' // Vuetify library
import '@mdi/font/css/materialdesignicons.css' // Material Design Icons
import * as components from 'vuetify/components' 

const vuetify = createVuetify({
  components,
  icons: {
    defaultSet: 'mdi', // Use Material Design Icons
  },
  theme: {
    defaultTheme: 'light', // or 'dark'
  },
})

export default vuetify
