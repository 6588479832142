<template>  
      <!-- Disclaimer Banner -->
      <v-footer v-if="isVisible"
        absolute 
        class="sticky-disclaimer glass-card py-3 px-5"
        
      >
        <v-container>
          <v-row :class="$vuetify.display.mobile?'text-caption':''" class="align-center justify-between">
            <v-col cols="12" md="10">
              <p class="mb-0">
                <strong>Disclaimer:</strong>     We are not a competent office of any Government or representative of any Government Embassies, Consulates, or Missions. We are not a law firm, and this website does not offer legal advice, explanations, opinions, or recommendations regarding legal rights.
              </p>
            </v-col>
            <v-col cols="12" md="2"  class="text-end">
              <v-btn :size="$vuetify.display.mobile?'small':'large'"
              :block="$vuetify.display.mobile"
                color="primary" 
                class="text-white"
                @click="dismissDisclaimer"
              >
                Dismiss
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer> 
  </template>
  
  <script lang="ts">
  import { defineComponent, ref } from 'vue';
  
  export default defineComponent({
    name: 'DisclaimerBanner',
    setup() {
      const isVisible = ref(true);
  
      const dismissDisclaimer = () => {
        isVisible.value = false;
      };
  
      return {
        isVisible,
        dismissDisclaimer,
      };
    },
  });
  </script>
  
  <style scoped>
  .sticky-disclaimer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    color: #1c3764;
  }
  </style>
  