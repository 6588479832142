<template>
  <div translate="no">
    <div class="notranslate grid md:grid-cols-2 lg:grid-cols-3 p-4 shadow m-4 rounded-lg">
      123123123
      <v-text-field v-model="search" placeholder="Search" outlined dense hide-details>
        <template #prepend-inner>
          <v-icon left>mdi-magnify</v-icon>
        </template>
      </v-text-field>
      
      <v-data-table :search="search" hide-default-header height="300" :items-per-page="-1" :headers="headers" hide-default-footer :items="filteredCountries">
        <template v-slot:[`item.title`]="{ item }">
          <v-card width="100%" flat color="transparent" class="d-flex px-4" @click="doTranslation(item.code)">
            {{ item.flag }}
            <abbr class="my-auto ml-4"><b>{{ item?.title }}</b></abbr>
          </v-card>
        </template>
      </v-data-table>
    </div> 

    <div class="google_translate_element" id="google_translate_element">
      <slot></slot>
    </div>

    <v-overlay v-if="loading">
      <v-progress-circular size="55" color="primary darken-2" indeterminate></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import CountryFlag from 'vue-country-flag';
import { useStore } from 'vuex';

interface Country {
  code: string;
  title: string;
  flag: string;
}

export default defineComponent({
  name: 'Translator',
  components: {
    CountryFlag
  },
  props: {
    countries: {
      type: Array as () => Country[],
      default: () => []
    }
  },
  setup(props) {
    const google = (window as any).google;
    const doGTranslate = (window as any).doGTranslate;

    const store = useStore(); // Accessing Vuex store
    const search = ref('');
    const loading = ref(false);
    const defaultCountries = ref([
      { code: 'en|af', title: 'Afrikaans', flag: 'af' },
      { code: 'en|sq', title: 'Albanian', flag: 'sq' },
      { code: 'en|ar', title: 'Arabic', flag: 'ar' },
      { code: 'en|hy', title: 'Armenian', flag: 'hy' },
      { code: 'en|az', title: 'Azerbaijani', flag: 'az' },
      { code: 'en|eu', title: 'Basque', flag: 'eu' },
      { code: 'en|be', title: 'Belarusian', flag: 'be' },
      { code: 'en|bg', title: 'Bulgarian', flag: 'bg' },
      { code: 'en|ca', title: 'Catalan', flag: 'ca' },
      { code: 'en|zh-CN', title: 'Chinese (Simplified)', flag: 'zh-CN' },
      { code: 'en|zh-TW', title: 'Chinese (Traditional)', flag: 'zh-TW' },
      { code: 'en|hr', title: 'Croatian', flag: 'hr' },
      { code: 'en|cs', title: 'Czech', flag: 'cs' },
      { code: 'en|da', title: 'Danish', flag: 'da' },
      { code: 'en|nl', title: 'Dutch', flag: 'nl' },
      { code: 'en|en', title: 'English', flag: 'en' },
      { code: 'en|et', title: 'Estonian', flag: 'et' },
      { code: 'en|tl', title: 'Filipino', flag: 'tl' },
      { code: 'en|fi', title: 'Finnish', flag: 'fi' },
      { code: 'en|fr', title: 'French', flag: 'fr' },
      { code: 'en|de', title: 'German', flag: 'de' },
      { code: 'en|el', title: 'Greek', flag: 'el' },
      { code: 'en|hu', title: 'Hungarian', flag: 'hu' },
      { code: 'en|id', title: 'Indonesian', flag: 'id' },
      { code: 'en|ga', title: 'Irish', flag: 'ga' },
      { code: 'en|it', title: 'Italian', flag: 'it' },
      { code: 'en|ja', title: 'Japanese', flag: 'ja' },
      { code: 'en|ko', title: 'Korean', flag: 'ko' },
      { code: 'en|lt', title: 'Lithuanian', flag: 'lt' },
      { code: 'en|ms', title: 'Malay', flag: 'ms' },
      { code: 'en|no', title: 'Norwegian', flag: 'no' },
      { code: 'en|pl', title: 'Polish', flag: 'pl' },
      { code: 'en|pt', title: 'Portuguese', flag: 'pt' },
      { code: 'en|ro', title: 'Romanian', flag: 'ro' },
      { code: 'en|ru', title: 'Russian', flag: 'ru' },
      { code: 'en|es', title: 'Spanish', flag: 'es' },
      { code: 'en|sv', title: 'Swedish', flag: 'sv' },
      { code: 'en|th', title: 'Thai', flag: 'th' },
      { code: 'en|tr', title: 'Turkish', flag: 'tr' },
      { code: 'en|uk', title: 'Ukrainian', flag: 'uk' }
    ]);
    
    const headers = ref([
      { name: 'Country', value: 'title' }
    ]);

    // Computed for filtered countries based on search
    const filteredCountries = computed(() => {
      return props.countries.filter(country =>
        country.title.toLowerCase().includes(search.value.toLowerCase())
      );
    });

    // Load Google Translate script dynamically
    const loadGoogleTranslateScript = (callback: Function) => {
  if (typeof google !== 'undefined' && google.translate) {
    callback();
  } else {
    const script = document.createElement('script');
    script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.type = 'text/javascript';
    script.onload = () => {
      console.log('Google Translate script loaded');
      callback();
    };
    script.onerror = (error) => {
      console.error('Error loading Google Translate script', error);
    };
    document.head.appendChild(script);
  }
};
    const googleTranslateElementInit = () => {
      new google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          autoDisplay: false,
          excluded: ['div[data-translate="false"]']
        },
        'google_translate_element'
      );
    };

    // Method for initiating translation
    const doTranslation = (data: string) => {
      console.log('doTranslation called with data:', data);
      loading.value = true;
      loadGoogleTranslateScript(() => {
        console.log('Google Translate script loaded');
        if (typeof  google !== 'undefined') {
          console.log('Google object found');
          const translateElementDiv = document.getElementById('google_translate_element');
          if (translateElementDiv) {
            console.log('#google_translate_element found');
            try {
              new google.translate.TranslateElement({
                pageLanguage: 'en',
                autoDisplay: false, 
              }, 'google_translate_element'); 
              if (typeof  doGTranslate !== 'undefined') {
                console.log('Calling doGTranslate with data:', data); 
                doGTranslate(data);
                const [fromLang, toLang] = data.split('|');
                store.dispatch('changeLang', toLang);
              } else {
                console.error('doGTranslate function is not available');
              }
            } catch (error) {
              console.error('Error during TranslateElement initialization:', error);
            }
          } else {
            console.error('#google_translate_element div not found in DOM');
          }
        } else {
          console.error('Google object is undefined');
        }
      });
    };

    return {
      search,
      loading,
      filteredCountries,
      headers,
      doTranslation
    };
  }
});
</script>

<style>
/* Hide the Google Translate spinner */
.goog-te-spinner-pos,
.goog-te-spinner-pos * {
  display: none !important;
}

.goog-te-gadget .goog-te-spinner-pos {
  visibility: hidden !important;
  display: none !important;
}

/* Other styles */
a:link {
  text-decoration: none;
  font-size: large;
  cursor: pointer;
}

.language-item {
  display: flex;
}

.language__text {
  color: black;
  margin-top: 7px;
  padding-left: 5px;
  text-decoration: none;
}

.language-item:hover {
  cursor: pointer;
  text-decoration: underline;
}

.flag {
  height: 40px;
}

h3 {
  cursor: pointer;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
</style>
