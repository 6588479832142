// store.ts
import { createStore } from 'vuex';

export interface State {
  lang: string | null;
}

const store = createStore<State>({
  state: {
    lang: null,
  },
  mutations: {
    setLang(state, lang: string) {
      state.lang = lang; // Mutate the state directly here
    },
  },
  actions: {
    changeLang({ commit }, lang: string) {
      commit('setLang', lang); // Call the mutation inside an action
    },
  },
  getters: {
    lang: (state) => state.lang, // Get the lang from state
  },
});

export default store;
