<template>
  <v-app-bar color="#1c3764" class="rounded-0 elevation-2" height="99">
    <v-row>
      <v-col cols="7" md="6" class="d-flex">
        <v-img
          src="/img/banner.png"
          alt="Logo"
          contain
          width="400"
          height="80"
        ></v-img>
      </v-col>

      <v-spacer></v-spacer>
      <v-col cols="4" md="2" class="mr-auto my-auto"> 
        <v-menu
          offset-y
          v-model="closeLanguage"
          left
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              rounded
              outlined
              color="primary"
              class="notranslate px-2"
              style="text-transform: none; color: black;"
              v-bind="props"
            >
              <country-flag
                style="margin-top: -8px; max-width: 50px; height: 40px;"
                class="rounded-xl"
                :country="currentLanguage.flag"
              />
              &nbsp; {{ currentLanguage.title }}
            </v-btn>
          </template>

          <v-list>
            <!-- Use v-show or v-if to manage component lifecycle -->
            <v-list-item color="white">
              <translatorForm
                v-show="closeLanguage"
                :countries="list_languages"
              />
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-app-bar>
</template>


<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import translatorForm from './Translator.vue';

interface Language {
  title: string; 
  code: string;
  flag: string;
}

export default defineComponent({
  name: 'App',
  components: {
    translatorForm,
  },
  setup() {
    const store = useStore(); // Accessing Vuex store
    const closeLanguage = ref(false);
    const selected_language = ref('en');
    const list_languages = ref<Language[]>([
      { code: 'en|af', title: 'Afrikaans', flag: 'af' },
      { code: 'en|sq', title: 'Albanian', flag: 'sq' },
      { code: 'en|ar', title: 'Arabic', flag: 'ar' },
      { code: 'en|hy', title: 'Armenian', flag: 'hy' },
      { code: 'en|az', title: 'Azerbaijani', flag: 'az' },
      { code: 'en|eu', title: 'Basque', flag: 'eu' },
      { code: 'en|be', title: 'Belarusian', flag: 'be' },
      { code: 'en|bg', title: 'Bulgarian', flag: 'bg' },
      { code: 'en|ca', title: 'Catalan', flag: 'ca' },
      { code: 'en|zh-CN', title: 'Chinese (Simplified)', flag: 'zh-CN' },
      { code: 'en|zh-TW', title: 'Chinese (Traditional)', flag: 'zh-TW' },
      { code: 'en|hr', title: 'Croatian', flag: 'hr' },
      { code: 'en|cs', title: 'Czech', flag: 'cs' },
      { code: 'en|da', title: 'Danish', flag: 'da' },
      { code: 'en|nl', title: 'Dutch', flag: 'nl' },
      { code: 'en|en', title: 'English', flag: 'en' },
      { code: 'en|et', title: 'Estonian', flag: 'et' },
      { code: 'en|tl', title: 'Filipino', flag: 'tl' },
      { code: 'en|fi', title: 'Finnish', flag: 'fi' },
      { code: 'en|fr', title: 'French', flag: 'fr' },
      { code: 'en|de', title: 'German', flag: 'de' },
      { code: 'en|el', title: 'Greek', flag: 'el' },
      { code: 'en|hu', title: 'Hungarian', flag: 'hu' },
      { code: 'en|id', title: 'Indonesian', flag: 'id' },
      { code: 'en|ga', title: 'Irish', flag: 'ga' },
      { code: 'en|it', title: 'Italian', flag: 'it' },
      { code: 'en|ja', title: 'Japanese', flag: 'ja' },
      { code: 'en|ko', title: 'Korean', flag: 'ko' },
      { code: 'en|lt', title: 'Lithuanian', flag: 'lt' },
      { code: 'en|ms', title: 'Malay', flag: 'ms' },
      { code: 'en|no', title: 'Norwegian', flag: 'no' },
      { code: 'en|pl', title: 'Polish', flag: 'pl' },
      { code: 'en|pt', title: 'Portuguese', flag: 'pt' },
      { code: 'en|ro', title: 'Romanian', flag: 'ro' },
      { code: 'en|ru', title: 'Russian', flag: 'ru' },
      { code: 'en|es', title: 'Spanish', flag: 'es' },
      { code: 'en|sv', title: 'Swedish', flag: 'sv' },
      { code: 'en|th', title: 'Thai', flag: 'th' },
      { code: 'en|tr', title: 'Turkish', flag: 'tr' },
      { code: 'en|uk', title: 'Ukrainian', flag: 'uk' },
    ]);

    const lang = computed(() => {
      return store.state.lang || 'en'; // Fallback to 'en' if lang is null
    });

    const currentLanguage = computed(() => {
      const selectedLang = list_languages.value.filter(
        (val) => val.code.split('|')[1] === selected_language.value
      );
      return selectedLang[0] || { title: 'English',   code: 'en|en', flag: 'gb' };
    });
    
    watch(
      () => store.state.lang,
      (val) => {
        if (val) {
          closeLanguage.value = false;
        }
      }
    );
    return {
      list_languages,
      lang,
      currentLanguage,
      closeLanguage,
      selected_language,
    };
  },
});
</script>

<style scoped>
.glass-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px); 
}
</style>

<style> 
.goog-te-spinner-pos, .skiptranslate, .goog-te-spinner-frame, .goog-te-banner-frame.skiptranslate {
    display: none !important;
}
</style>
